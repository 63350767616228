import { getSession } from 'next-auth/react';
import * as jwt from 'jsonwebtoken';

export async function request(url: string, config: RequestInit) {
  try {
    const { user } = await getSession();
    console.log('Creating request for:', user);
    const token = jwt.sign(user, process.env.NX_SECRET);
  
    const authHeader = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
      'User-Id': user?.email,
    };
  
    config.headers = authHeader;
  
    console.log('Final config: ', config);
  } catch (error) {
    console.error('An error occurred in fetch.js inside request:', error);
  }  
  return fetch(url, config);
}
